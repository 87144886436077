export type Primitive = string | null | number | undefined | symbol | boolean;
export type ArrayType<T> = T extends (infer U)[] ? U : never;
export type PatchPartial<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type Leave<T, Allowed extends unknown = Primitive> = {
  [P in keyof T]: Extract<T[P], Allowed>;
};
export type Kill<T, NotAllowed extends unknown = null> = {
  [P in keyof T]: Exclude<T[P], NotAllowed>;
};
export type ToServiceObject<T> = {
  [K in keyof T]?: T[K] | null | undefined;
};
export type FromServiceObject<T> = {
  [K in keyof T]: NonNullable<T[K]>;
};
export type FixServerObject<T, K extends keyof T> = Omit<T, K> &
  Required<{ [P in K]: Exclude<T[P], null | undefined> }>;

export type ConditionPartial<T, C, Rest> = T extends C ? Partial<Rest> : Rest;
export type PromiseType<T> = T extends Promise<infer U> ? U : never;

export const isSettledRejected = (
  input: PromiseSettledResult<unknown>,
): input is PromiseRejectedResult => input.status === 'rejected';

export const isSettledFulfilled = <T>(
  input: PromiseSettledResult<T>,
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled';

export type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[],
];

export type Join<K, P, S extends string = '.'> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : S}${P}`
    : never
  : never;

// export type DeepPaths<T, D extends number = 5> = [D] extends [never]
//   ? never
//   : T extends object
//   ? {
//       [K in keyof T]-?: K extends string | number
//         ? `${K}` | Join<K, DeepPaths<T[K], Prev[D]>, '.'>
//         : never;
//     }[keyof T]
//   : '';

export type Paths<T> = keyof T | string;

export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
    : '';

export const enumToArray = <T extends Record<string, any>>(en: T, isNumber = false) => {
  return Object.entries(en)
    .filter(([key]) => !isFinite(key as any))
    .map(([key, val]) => ({
      id: isNumber ? Number(val) : String(val),
      title: key,
    })) as { id: T[keyof T]; title: string }[];
};
