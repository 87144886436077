import * as dynamic from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  RTK_TAGS,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  rtkAdapterError,
} from 'utils/service';
import {
  GetUserProfileBondAlertsInput,
  GetUserProfileBondAlertsOutput,
  GetUserProfileBondsForProfileInput,
  GetUserProfileBondsForProfileItem,
} from './models';
import { PatchPartial } from 'utils/types';
import { IUserProfileBond } from '__generated__/api';

export * from './models';

const requestGet = API.api.userProfileBondsGetAllDynamicList;
const requestPatch = API.api.userProfileBondsPatchPartialUpdate;

export const apiUserProfileBonds = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserProfileBondAlerts: build.query<
      GetUserProfileBondAlertsOutput,
      GetUserProfileBondAlertsInput
    >({
      queryFn: async ({ userProfileID, bondID }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'bondID',
              'userProfileID',
              'alert1',
              'alert2',
              'alert3',
              'alert4',
              'alert5',
              'alert6',
              'alert7',
              'alert8',
              'bond.symbol',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('userProfileID', userProfileID, dynamic.equals),
                dynamic.makeFilter('bondID', bondID, dynamic.equals),
              )
              .join('&&'),
            Take: 1,
          });
          return rtkAdapterDynamicItem<GetUserProfileBondAlertsOutput>(res as unknown as any);
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getUserProfileBondsForProfile: build.query<
      Array<GetUserProfileBondsForProfileItem>,
      GetUserProfileBondsForProfileInput
    >({
      queryFn: async ({ userProfileID }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'bondID',
              'userProfileID',
              'alert1',
              'alert2',
              'alert3',
              'alert4',
              'alert5',
              'alert6',
              'alert7',
              'alert8',
              'bond.symbol',
              'bond.name',
              'bond.issuerName',
              'bond.issuerID',
              'bond.controllingShareHolder',
              'bond.issuerNum',
              'bond.equityTypeName',
              'bond.branchName',
              'bond.baseRate',
              'bond.lastDealRate',
              'bond.baseRateChangePercentage',
              'bond.yesterdayYield',
              'bond.beginOfYearYield',
              'bond.grossYieldToMaturity',
              'bond.grossDuration',
              'bond.grossYieldToMaturityDeltaByDuration',
              'bond.linkedAssetName',
              'bond.weekAverageNIS',
              'bond.dailyTurnover',
              'bond.annualInterest',
              'bond.grossAdjustedValue',
              'bond.listedFortune',
              'bond.redemptionOrExpirationDate',
              'bond.issuingDate',
              'bond.lastRateDate',
              'bond.tradingStage',
              'bond.bondMidroogRank',
              'bond.bondMidroogOutlook',
              'bond.bondMaalotRank',
              'bond.bondMaalotOutlook',
              'bond.flowYear1',
              'bond.flowYear2',
              'bond.flowYear3',
              'bond.flowYear4',
              'bond.flowYear5',
              'bond.flowYear6',
              'bond.flowYear7',
              'bond.flowYearElse',
            ),
            Filter: dynamic
              .mergeFilters(dynamic.makeFilter('userProfileID', userProfileID, dynamic.equals))
              .join('&&'),
          });
          const { data } = rtkAdapterDynamicToSource<GetUserProfileBondsForProfileItem>(
            res as unknown as any,
          );
          return { data };
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      providesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),
    patchUserProfileBondAlert: build.mutation<
      void,
      PatchPartial<IUserProfileBond, 'id' | 'userProfileID' | 'bondID'>
    >({
      queryFn: async (input) => {
        try {
          const { userProfileID, bondID, ...rest } = input;
          await requestPatch(...makePatchArgs(rest));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),
  }),
});
