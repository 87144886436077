import * as dynamic from 'utils/dynamic';
import { dateMore, decoratorIsNotNullable, equals } from 'utils/dynamic';
import { APP_ERROR_CODES, AppError } from 'utils/errors';
import { API, apiRtk, RTK_TAGS, rtkAdapterError } from 'utils/service';
import {
  GetGridPortfolioStocksInput,
  GetGridPortfolioStocksResponse,
  GridStockAlerts10Response,
  GridStockAlerts11Response,
  GridStockAlerts12Response,
  GridStockAlerts9Response,
  GridStockYieldAlerts13Response,
  GridStockYieldAlerts14Response,
  GridStockYieldAlerts15Response,
  GridStockYieldAlerts16Response,
  IStockAlertsArg,
  ISystemStockArg,
  ISystemStockOutput,
  UpdateIsActivePortfolioStocksInput,
} from './models';

const requestGet = API.api.stocksHelperGetUserStocksDynamicList;
const requestGetSystem = API.api.stocksHelperGetSystemStocksDynamicList;

export * from './models';

const stockAlertsFilter = ({
  dailyTurnoverMax,
  dailyTurnoverMin,
  stocks,
  lastRateDate,
}: Omit<IStockAlertsArg, 'take' | 'skip' | 'orderBy' | 'UserProfileID'>) =>
  dynamic
    .mergeFilters(
      dynamic.makeFilter('dailyTurnover', [dailyTurnoverMin, dailyTurnoverMax], dynamic.minMax),
      dynamic.makeFilter('name', stocks, dynamic.decoratorValueArray(dynamic.equals)),
      dynamic.makeFilter('lastRateDate', lastRateDate, decoratorIsNotNullable(dateMore)),
    )
    .join('&&');

export const apiStocksHelper = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridStockAlerts12: build.query<GridStockAlerts12Response, IStockAlertsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                stockAlertsFilter(filters),
                dynamic.more('alert12', 'baseRateChangePercentage'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'stockID',
              'symbol',
              'issuerName',
              'issuerNum',
              'name',
              'viewNumberOfBonds',
              'baseRateChangePercentage',
              'calcPercentchangeAvgYear',
              'calcPercentchangeSDYear',
              'alert12',
              'viewMidroogRatingMaxTitle',
              'viewMidroogRatingMinTitle',
              'issuerID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridStockAlerts9: build.query<GridStockAlerts9Response, IStockAlertsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                stockAlertsFilter(filters),
                dynamic.more('alert9/100', 'weekYield'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'stockID',
              'symbol',
              'issuerName',
              'issuerNum',
              'name',
              'viewNumberOfBonds',
              'weekYield',
              'alert9',
              'viewMidroogRatingMaxTitle',
              'viewMidroogRatingMinTitle',
              'issuerID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridStockAlerts10: build.query<GridStockAlerts10Response, IStockAlertsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                stockAlertsFilter(filters),
                dynamic.more('alert10/100', 'allMonthYield'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'stockID',
              'symbol',
              'issuerName',
              'issuerNum',
              'name',
              'viewNumberOfBonds',
              'allMonthYield',
              'alert10',
              'viewMidroogRatingMaxTitle',
              'viewMidroogRatingMinTitle',
              'issuerID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridStockAlerts11: build.query<GridStockAlerts11Response, IStockAlertsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                stockAlertsFilter(filters),
                dynamic.more('alert11/100', 'allYearYield'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'stockID',
              'symbol',
              'issuerName',
              'issuerNum',
              'name',
              'viewNumberOfBonds',
              'allYearYield',
              'alert11',
              'viewMidroogRatingMaxTitle',
              'viewMidroogRatingMinTitle',
              'issuerID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridStockYieldAlerts13: build.query<GridStockYieldAlerts13Response, IStockAlertsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                stockAlertsFilter(filters),
                dynamic.more('alert13/100', 'calcReturnMinusTAIndexWeek'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'stockID',
              'symbol',
              'issuerName',
              'issuerNum',
              'name',
              'viewNumberOfBonds',
              'weekYield',
              'calcYield7TA125',
              'calcReturnMinusTAIndexWeek',
              'alert13',
              'viewMidroogRatingMaxTitle',
              'viewMidroogRatingMinTitle',
              'issuerID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridStockYieldAlerts14: build.query<GridStockYieldAlerts14Response, IStockAlertsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                stockAlertsFilter(filters),
                dynamic.more('alert14/100', 'calcReturnMinusTAIndexMonth'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'stockID',
              'symbol',
              'issuerName',
              'issuerNum',
              'name',
              'viewNumberOfBonds',
              'allMonthYield',
              'calcYield30TA125',
              'calcReturnMinusTAIndexMonth',
              'alert14',
              'viewMidroogRatingMaxTitle',
              'viewMidroogRatingMinTitle',
              'issuerID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridStockYieldAlerts15: build.query<GridStockYieldAlerts15Response, IStockAlertsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                stockAlertsFilter(filters),
                dynamic.more('alert15/100', 'calcReturnMinusTAIndexYear'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'stockID',
              'symbol',
              'issuerName',
              'issuerNum',
              'name',
              'viewNumberOfBonds',
              'allYearYield',
              'calcYield365TA125',
              'calcReturnMinusTAIndexYear',
              'alert15',
              'viewMidroogRatingMaxTitle',
              'viewMidroogRatingMinTitle',
              'issuerID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridStockYieldAlerts16: build.query<GridStockYieldAlerts16Response, IStockAlertsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                stockAlertsFilter(filters),
                dynamic.more('alert16/100', 'calcPercentchangeMinusRf'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'stockID',
              'symbol',
              'issuerName',
              'issuerNum',
              'name',
              'viewNumberOfBonds',
              'calcPercentchangeMinusRfAvg',
              'calcPercentchangeMinusRfSD',
              'calcPercentchangeMinusRf',
              'alert16',
              'viewMidroogRatingMaxTitle',
              'viewMidroogRatingMinTitle',
              'issuerID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),

    getGridPortfolioStocks: build.query<
      GetGridPortfolioStocksResponse,
      GetGridPortfolioStocksInput
    >({
      queryFn: async (input) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'stockID',
              'tradeStatusID',
              'tradeStatusName',
              'isActive',
              'symbol',
              'name',
              'controllingShareHolder',
              'issuerID',
              'issuerNum',
              'issuerName',
              'branchID',
              'branchName',
              'baseRate',
              'lastDealRate',
              'baseRateChangePercentage',
              'weekYield',
              'allMonthYield',
              'beginOfYearYield',
              'allYearYield',
              'pbRatio',
              'currentMarketWorth',
              'weekAverageTurnover',
              'dailyTurnover',
              'equityTypeID',
              'equityTypeName',
              'listedFortune',
              'dividendYield',
              'peRetio',
              'yesterdayYield',
              'issuingDate',
              'lastRateDate',
            ),
            UserProfileID: input.userProfileID,
          });

          return res;
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),
    updateIsActivePortfolioStocks: build.mutation<void, UpdateIsActivePortfolioStocksInput>({
      queryFn: async (input) => {
        try {
          const isAllChecked = input.items.every((item) => item.isActive);

          if (isAllChecked) {
            const ids = input.items.map(({ stockID }) => stockID);
            await API.api.stocksHelperLoadUserStocksAlertsByIDsCreate(ids);
            return { data: undefined };
          }

          const isAllUnchecked = input.items.every((item) => !item.isActive);

          if (isAllUnchecked) {
            const ids = input.items.map(({ stockID }) => stockID);
            await API.api.stocksHelperUnLoadUserStocksAlertsByIDsCreate(ids);
            return { data: undefined };
          }

          throw new AppError({ code: APP_ERROR_CODES.UNEXPECTED, message: 'not the same values' });
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),

    stocksResetToUserDefault: build.mutation<void, void>({
      queryFn: async () => {
        try {
          await API.api.stocksHelperResetUserStocksAlertsToUserDefaultUpdate();
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),
    stocksResetToSystemDefault: build.mutation<void, void>({
      queryFn: async () => {
        try {
          await API.api.stocksHelperResetUserStocksAlertsToSystemDefaultUpdate();
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),

    stocksLoadUserStocksAlertsBySymbols: build.mutation<string, string[]>({
      queryFn: async (input) => {
        try {
          const res = await API.api.stocksHelperLoadUserStocksAlertsBySymbolsCreate(input);
          return { data: res.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),
    stocksLoadUserStocksAlertsByIssuers: build.mutation<string, string[]>({
      queryFn: async (input) => {
        try {
          const res = await API.api.stocksHelperLoadUserStocksAlertsByIssuersCreate(input);
          return { data: res.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),
    stocksDeleteUserBondsAlerts: build.mutation({
      queryFn: async () => {
        try {
          await API.api.stocksHelperDeleteUserStocksAlertsDelete();
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),
    stocksInsertNewUserStocksAlerts: build.mutation({
      queryFn: async () => {
        try {
          await API.api.stocksHelperInsertNewUserStocksAlertsCreate();
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),

    getGridSystemStocks: build.query<ISystemStockOutput, ISystemStockArg>({
      queryFn: async ({ take: Take, skip: Skip, userPatientProfileID, orderBy, ...filters }) => {
        try {
          return await requestGetSystem({
            UserProfileID: userPatientProfileID,
            OrderBy: dynamic.orderBy(orderBy),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  'symbol',
                  filters.stocks,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'equityTypeID',
                  filters.equityTypeIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'branchID',
                  filters.branchIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter('isActive', filters.isActive, decoratorIsNotNullable(equals)),
              )
              .join('&&'),
            Take,
            Skip,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
  }),
});
