import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, parseErrorData, rtkAdapterDynamicItem } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import * as dynamic from 'utils/dynamic';
import { AxiosResponse } from 'axios';
import { TradingEtlData } from './slice';

export const actionTradingEtlFetchData = createAsyncThunk<
  TradingEtlData,
  void,
  AppAsyncThunkConfig
>(`TRADING_ETL/actionTradingEtlFetchData`, async (_) => {
  try {
    const result = await API.api.dataEtLsGetAllDynamicList({
      Select: dynamic.select(
        'id',
        'finished',
        'entryDate',
        'new { userProfile.appIdentityUserID, userProfile.fullName } as userProfile',
      ),
      Take: 1,
      OrderBy: 'entryDate desc',
    });

    const { data } = rtkAdapterDynamicItem(result) as unknown as AxiosResponse<TradingEtlData>;
    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
