import * as dynamic from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  RTK_TAGS,
  rtkAdapterDynamicItem,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { ILabel } from '__generated__/api';
import { IGridLabelParams, IGridLabelResponse, LABEL_SOURCE_ID } from './models';
import { AxiosResponse } from 'axios';
import { decoratorIsNotNullable, equals } from 'utils/dynamic';

const REVALIDATE_KEY = RTK_TAGS.LABELS;

const requestGet = API.api.labelsGetAllDynamicList;
const requestPost = API.api.labelsCreateCreate;
const requestPatch = API.api.labelsPatchPartialUpdate;
const requestDelete = API.api.labelsDeleteDelete;

export * from './models';

export const apiLabels = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAppLabels: build.query<ILabel[], { languageID: string }>({
      queryFn: async ({ languageID }) => {
        try {
          const {
            data: { items },
          } = await requestGet({
            Select: dynamic.select('id', 'labelKey', 'title'),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('languageID', languageID, dynamic.equals),
                dynamic.makeFilter(
                  'labelSourceID',
                  LABEL_SOURCE_ID.APP,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
                dynamic.makeFilter('isActive', true, dynamic.equals),
              )
              .join('&&'),
          });
          return { data: items };
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getGridLabels: build.query<IGridLabelResponse, IGridLabelParams>({
      queryFn: async ({
        languageID,
        search,
        take: Take,
        skip: Skip,
        orderBy,
        labelSourceID,
        isActive,
      }) => {
        try {
          const result = await requestGet({
            Select: dynamic.select(
              'id',
              'labelKey',
              'title',
              'isActive',
              'language.title as languageTitle',
              'labelSourceID',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(['title', 'labelKey'], search, dynamic.contains),
                dynamic.makeFilter(
                  'languageID',
                  languageID,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'labelSourceID',
                  labelSourceID,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
                dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
              )
              .join('&&'),
            OrderBy: orderBy.field
              ? dynamic.orderBy(orderBy.field, orderBy.order)
              : 'isActive desc, language.title asc',
            Take,
            Skip,
            Count: true,
          });

          return result as AxiosResponse<IGridLabelResponse>;
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },

      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getLabel: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await requestGet({
            Take: 1,
            Filter: dynamic.makeFilter('id', id, dynamic.equals),
          });

          return rtkAdapterDynamicItem(result);
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postLabel: build.mutation<void, ILabel>({
      queryFn: async (data) => {
        try {
          await requestPost(data);
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchLabel: build.mutation<void, PatchPartial<ILabel, 'id'>>({
      queryFn: async (data) => {
        try {
          await requestPatch(...makePatchArgs(data));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteLabel: build.mutation<void, PatchPartial<ILabel, 'id'>>({
      queryFn: async (data) => {
        try {
          await requestDelete(String(data?.id));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
