export const TIME_FORMAT_DATE = 'dd/MM/yyyy';
export const TIME_FORMAT_DATE_SECONDARY = 'dd-MM-yyyy';
export const TIME_FORMAT_DATE_TIME = `${TIME_FORMAT_DATE} HH:mm`;
export const TIME_FORMAT_EXCEL_DATE = 'yyyy-MM-dd';

export let PIPELINE_VARIABLES = {
  api: import.meta.env.VITE_API_SERVER,
};
export enum HELP_ME_LABEL {
  helpWithOtc = 'help-with-otc',
  helpWithVit = 'help-with-vit',
}

export enum BOND_STOCK_IDS {
  ISSUER_ID = 'issuerID',
  BRANCH_ID = 'branchID',
  LINKED_ASSET_ID = 'linkedAssetID',
  EQUITY_TYPE_ID = 'equityTypeID',
  MAALOT_RATING_ID = 'maalotRatingID',
  MIDROOG_RATING_ID = 'midroogRatingID',
  TRADE_STATUS_ID = 'tradeStatusID',
}

export const STOCK_QUERY_NAME = 'stock';

export const BOND_QUERY_NAME = 'bond';

export const TAKE_PAGES = 1000;

export const ERROR_MESSAGES = [
  '!error!N/A!Data',
  '!error!ber!no',
  '!error!NaN!Not a Number',
  '!error!NOF!Suitable',
];

export const MIN_WIDTH_FORM = 180;
