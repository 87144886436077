import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Collapse, ListItemButton, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { navigationEmitter } from 'layouts/private/emitter';
import { resolvePath, To, useLocation, useNavigate } from 'react-router-dom';
import style from './index.module.scss';
import { useLanguage } from 'hooks/use-language';

const checkIsActive = (
  option: Pick<NavigationOption, 'to' | 'options'>,
  pathname: string,
): boolean => {
  const { to, options } = option;
  const path = to && resolvePath(to).pathname;

  const isActiveCurrent = path === pathname;

  if (isActiveCurrent) {
    return true;
  }

  if (options) {
    return options.some((op) => checkIsActive(op, pathname));
  }

  return false;
};

export interface NavigationOption {
  title: string;
  Icon?: React.ReactNode;
  to?: To;
  options?: NavigationOption[];
}
export interface NavigationProps extends NavigationOption {
  isMenuOpen?: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  level?: number;
}

export const NavigationItem: React.FC<NavigationProps> = ({
  Icon,
  title,
  options,
  to,
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  level = 1,
}) => {
  const { pathname } = useLocation();
  const { isRTL } = useLanguage();
  const navigate = useNavigate();
  const subItems = useMemo(() => {
    return options || [];
  }, [options]);

  const isActive = useMemo(() => {
    return checkIsActive({ to, options }, pathname);
  }, [to, pathname, options]);

  const [isOpen, setIsOpen] = useState(isActive);

  const componentID = useId();

  useEffect(() => {
    const onToggleCollapse = (params: { componentID: string; value: boolean }) => {
      if (params.componentID === componentID) {
        setIsOpen(params.value);
      } else {
        setIsOpen(false);
      }
    };
    if (subItems.length > 0) {
      navigationEmitter.on('onToggleCollapse', onToggleCollapse);
    }
    return () => {
      navigationEmitter.off('onToggleCollapse', onToggleCollapse);
    };
  }, [subItems, componentID]);

  const primary = title;

  const onClickButton = useCallback(() => {
    if (to) {
      onMenuClose && onMenuClose();
      navigate(to);
    } else {
      onMenuOpen && onMenuOpen();
      navigationEmitter.emit('onToggleCollapse', {
        componentID,
        value: !isMenuOpen ? true : !isOpen,
      });
    }
  }, [to, navigate, onMenuClose, onMenuOpen, componentID, isOpen, isMenuOpen]);

  const isCollapseOpen = isMenuOpen && isOpen;
  const isInner = level > 1;
  const isLast = subItems.length === 0;
  const theme = useTheme();
  return (
    <>
      <ListItemButton
        disableGutters
        className={clsx(style.item, {
          [style.itemInner]: isInner,
          [style.itemLast]: isLast,
          [style.itemSelected]: isActive,
          [style.itemOpen]: isMenuOpen,
        })}
        onClick={onClickButton}
        sx={{
          borderRightColor: isActive ? theme.palette.primary.main : 'transparent',
        }}
        selected={isActive}
      >
        {isMenuOpen ? (
          <Typography component={'div'} variant={'body2'} className={style.itemLong}>
            <Box
              lineHeight={0}
              fontSize={'2.4rem'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {Icon}
            </Box>
            <Typography
              color={isInner ? (isActive ? 'primary' : 'textPrimary') : 'primary'}
              variant={'body2'}
              component={'div'}
              minWidth={140}
            >
              {primary}
            </Typography>
            {!isLast && <ExpandMore className={clsx(style.arrow, isOpen && style.arrowOpen)} />}
          </Typography>
        ) : (
          <Typography component={'div'} textAlign={'center'} className={style.itemSmall}>
            <Box className={style.itemSmallBox}>
              {Icon}

              {!isLast && (
                <ChevronRightIcon
                  className={clsx(style.itemSmallArrow, isRTL && style.itemSmallArrowRtl)}
                  color={'inherit'}
                />
              )}
            </Box>
            <Typography textAlign={'center'} pt={0.5} pb={0.4}>
              {primary}
            </Typography>
          </Typography>
        )}
      </ListItemButton>
      {!isLast && (
        <Collapse in={isCollapseOpen}>
          {subItems.map((option, i) => {
            return (
              <NavigationItem
                key={i}
                isMenuOpen={isMenuOpen}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                title={option.title}
                to={option.to}
                level={level + 1}
              />
            );
          })}
        </Collapse>
      )}
    </>
  );
};
