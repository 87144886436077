import * as dynamic from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  RTK_TAGS,
  rtkAdapterDynamicItem,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { GetUserProfileStockAlertsInput, GetUserProfileStockAlertsOutput } from './models';

import { IUserProfileStock } from '__generated__/api';

export * from './models';

const requestGet = API.api.userProfileStocksGetAllDynamicList;
const requestPatch = API.api.userProfileStocksPatchPartialUpdate;

export const apiUserProfileStocks = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserProfileStockAlerts: build.query<
      GetUserProfileStockAlertsOutput,
      GetUserProfileStockAlertsInput
    >({
      queryFn: async ({ userProfileID, stockID }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'stockID',
              'userProfileID',
              'stock.symbol',
              'alert1',
              'alert2',
              'alert3',
              'alert4',
              'alert5',
              'alert6',
              'alert7',
              'alert8',
              'alert10',
              'alert11',
              'alert12',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('userProfileID', userProfileID, dynamic.equals),
                dynamic.makeFilter('stockID', stockID, dynamic.equals),
              )
              .join('&&'),
            Take: 1,
          });
          return rtkAdapterDynamicItem<GetUserProfileStockAlertsOutput>(res as unknown as any);
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    patchUserProfileStockAlert: build.mutation<
      void,
      PatchPartial<IUserProfileStock, 'id' | 'userProfileID' | 'stockID'>
    >({
      queryFn: async (input) => {
        try {
          const { userProfileID, stockID, ...rest } = input;
          await requestPatch(...makePatchArgs(rest));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),
  }),
});
