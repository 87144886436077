import { Unset } from 'utils/numbers';

export const PREFIX = 'PROFILE_STOCKS';

type BaseItem = { id: Unset; __isSelected: boolean };

export const selectItemsAll = <T extends BaseItem>(items: Array<T>, option: { value: boolean }) => {
  items.forEach((item) => {
    item.__isSelected = option.value;
  });
};
export const toggleItem = <T extends BaseItem>(items: Array<T>, id: string) => {
  const item = items.find((item) => item.id === id);

  if (item) {
    item.__isSelected = !item.__isSelected;
  }
};
