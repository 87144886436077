import * as dynamic from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IUserProfilePermission } from '__generated__/api';
import { IGridUserPermissionsArg, IGridUserPermissionsResponse } from './models';

export * from './models';

const REVALIDATE_KEY = RTK_TAGS.USER_PERMISSIONS;

const requestGet = API.api.userProfilePermissionsGetAllDynamicList;
const requestPatch = API.api.userProfilePermissionsPatchPartialUpdate;

export const apiUserProfilePermissions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridUserProfilePermission: build.query<
      IGridUserPermissionsResponse,
      IGridUserPermissionsArg
    >({
      queryFn: async ({ orderBy }) => {
        try {
          const result = await requestGet({
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Count: true,
          });

          return result;
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },

      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getUserProfilePermissionSource: build.query({
      queryFn: async (arg: void) => {
        try {
          const response = await requestGet({
            Select: dynamic.select('id', 'title'),
            Filter: dynamic
              .mergeFilters(dynamic.makeFilter('isActive', true, dynamic.equals))
              .join('&&'),
            OrderBy: 'title asc, isActive desc',
          });

          return rtkAdapterDynamicToSource<Pick<IUserProfilePermission, 'id' | 'title'>>(response);
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    patchUserProfilePermission: build.mutation<
      IUserProfilePermission,
      PatchPartial<IUserProfilePermission, 'id'>
    >({
      queryFn: async (data) => {
        try {
          const result = await requestPatch(...makePatchArgs(data));
          return result;
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
