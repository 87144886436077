import { startOfDay, subDays } from 'date-fns';

interface GetSearchFilterOptions<T> {
  value: string;
  fields: (keyof T)[];
}
export const getSearchFilter = <T extends Record<string, any>>(
  options: GetSearchFilterOptions<T>,
) => {
  const { value, fields } = options;
  const valueWords = String(value).toLowerCase().replace(/ +/gi, ' ').split(' ');
  return (item: T) =>
    valueWords.every((word) =>
      fields.some((fieldKey) =>
        String(item[fieldKey] || '')
          .toLowerCase()
          .includes(word),
      ),
    );
};

export interface IInitFilter {
  grossYieldToMaturityMin: number | null | undefined;
  grossYieldToMaturityMax: number | null | undefined;
  grossDurationMax: number | null | undefined;
  grossDurationMin: number | null | undefined;
  weekAverageNISMax: number | null | undefined;
  weekAverageNISMin: number | null | undefined;
  dailyTurnoverMax: number | null | undefined;
  dailyTurnoverMin: number | null | undefined;
  grossYieldToMaturityDeltaByDurationMax: number | null | undefined;
  grossYieldToMaturityDeltaByDurationMin: number | null | undefined;
  midroogRatings: [] | undefined;
  bonds: [] | undefined;
  lastRateDate: Date | null | undefined;
  institutional: boolean | undefined;
}

const lastRateDate = startOfDay(subDays(new Date(), 3));

export const initFilter: IInitFilter = {
  grossYieldToMaturityMin: 0.02,
  grossYieldToMaturityMax: null,
  grossDurationMax: null,
  grossDurationMin: 0.5,
  weekAverageNISMax: null,
  weekAverageNISMin: null,
  dailyTurnoverMax: null,
  dailyTurnoverMin: null,
  grossYieldToMaturityDeltaByDurationMax: null,
  grossYieldToMaturityDeltaByDurationMin: 0.02,
  midroogRatings: [],
  bonds: [],
  lastRateDate,
  institutional: true,
};

export const initDashboardFilter: IInitFilter = {
  grossYieldToMaturityMin: null,
  grossYieldToMaturityMax: null,
  grossDurationMax: null,
  grossDurationMin: null,
  weekAverageNISMax: null,
  weekAverageNISMin: null,
  dailyTurnoverMax: null,
  dailyTurnoverMin: null,
  grossYieldToMaturityDeltaByDurationMax: null,
  grossYieldToMaturityDeltaByDurationMin: null,
  midroogRatings: [],
  bonds: [],
  lastRateDate,
  institutional: true,
};

export const filtersSomeIDs = (ids: string[], value: any) => {
  return ids.length === 0 ? true : ids.some((id) => id === value);
};
