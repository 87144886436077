import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetGridStocksForPortfolioItem } from 'services/stock';
import { apiStocksHelper } from 'services/stocks-helper';
import { GetUserProfileStocksForProfileItem } from 'services/user-profile-stocks';
import * as dynamic from 'utils/dynamic';
import { decoratorIsNotNullable, equals } from 'utils/dynamic';
import { API, parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { PREFIX } from './helpers';
import {
  selectProfileStocksCustomDataSelected,
  selectProfileStocksSystemDataSelected,
} from './selectors';

export const actionProfileStocksSystemFetch = createAsyncThunk<
  Array<GetGridStocksForPortfolioItem>,
  void,
  AppAsyncThunkConfig
>(`${PREFIX}/fetchSystem`, async (_) => {
  try {
    const { data } = await API.api.stocksGetAllDynamicList({
      Select: dynamic.select(
        'id',
        'name',
        'symbol',
        'issuerName',
        'issuerNum',
        'issuerID',
        'equityTypeName',
        'branchName',
        'equityTypeID',
        'branchID',
      ),
      Filter: dynamic
        .mergeFilters(dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)))
        .join('&&'),
    });

    return data.items as unknown as Array<GetGridStocksForPortfolioItem>;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionProfileStocksCustomFetch = createAsyncThunk<
  Array<GetUserProfileStocksForProfileItem>,
  { userProfileID: string },
  AppAsyncThunkConfig
>(`${PREFIX}/fetchCustom`, async ({ userProfileID }) => {
  try {
    const { data } = await API.api.userProfileStocksGetAllDynamicList({
      Select: dynamic.select(
        'id',
        'stockID',
        'userProfileID',
        'alert1',
        'alert2',
        'alert3',
        'alert4',
        'alert5',
        'alert6',
        'alert7',
        'alert8',
        'stock.symbol',
        'stock.name',
        'stock.controllingShareHolder',
        'stock.issuerName',
        'stock.issuerNum',
        'stock.issuerID',
        'stock.equityTypeID',
        'stock.equityTypeName',
        'stock.branchName',
        'stock.branchID',
        'stock.baseRate',
        'stock.lastDealRate',
        'stock.baseRateChangePercentage',
        'stock.weekYield',
        'stock.allMonthYield',
        'stock.beginOfYearYield',
        'stock.allYearYield',
        'stock.pbRatio',
        'stock.currentMarketWorth',
        'stock.weekAverageTurnover',
        'stock.dailyTurnover',
        'stock.listedFortune',
        'stock.dividendYield',
        'stock.yesterdayYield',
        'stock.issuingDate',
        'stock.lastRateDate',
        'stock.tradingStage',
        'stock.viewNumberOfBonds',
      ),
      Filter: dynamic
        .mergeFilters(dynamic.makeFilter('userProfileID', userProfileID, dynamic.equals))
        .join('&&'),
    });

    return data.items as unknown as Array<GetUserProfileStocksForProfileItem>;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionProfileStocksCustomRemove = createAsyncThunk<
  void,
  { userProfileID: string },
  AppAsyncThunkConfig
>(`${PREFIX}/removeCustom`, async ({ userProfileID }, { getState, dispatch }) => {
  try {
    const selected = selectProfileStocksCustomDataSelected(getState());

    const items = selected.map(({ stockID }) => ({
      stockID: String(stockID),
      isActive: false,
    }));

    await dispatch(
      apiStocksHelper.endpoints.updateIsActivePortfolioStocks.initiate({
        userProfileID,
        items,
      }),
    ).unwrap();

    return undefined;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionProfileStocksSystemAttach = createAsyncThunk<
  void,
  { userProfileID: string },
  AppAsyncThunkConfig
>(`${PREFIX}/systemAttach`, async ({ userProfileID }, { getState, dispatch }) => {
  try {
    const selected = selectProfileStocksSystemDataSelected(getState());

    const items = selected
      .filter((row) => {
        return !row.__isInCustom;
      })
      .map(({ id }) => ({
        stockID: id,
        isActive: true,
      }));

    await dispatch(
      apiStocksHelper.endpoints.updateIsActivePortfolioStocks.initiate({
        userProfileID,
        items,
      }),
    ).unwrap();

    return undefined;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionProfileStocksSystemDetach = createAsyncThunk<
  void,
  { userProfileID: string },
  AppAsyncThunkConfig
>(`${PREFIX}/systemDetach`, async ({ userProfileID }, { getState, dispatch }) => {
  try {
    const selected = selectProfileStocksSystemDataSelected(getState());

    const items = selected
      .filter((row) => {
        return row.__isInCustom;
      })
      .map(({ id }) => ({
        stockID: id,
        isActive: false,
      }));

    await dispatch(
      apiStocksHelper.endpoints.updateIsActivePortfolioStocks.initiate({
        userProfileID,
        items,
      }),
    ).unwrap();

    return undefined;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
